import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { customFetchBase } from 'app/utils/api';
import { DefaultResponse, RequestWithPagination, ResponseWithPagination } from 'app/utils/types';
import { CreateEvnCondition, EnvironmentCondition, StandartSample, Units, UpdateEnvConditionBody } from '../model';

export const dictsApi = createApi({
  reducerPath: 'dictsApi',
  baseQuery: customFetchBase,
  tagTypes: ['Environment-condition'],
  endpoints: (builder) => ({
    getEnvironmentConditions: builder.query<
      ResponseWithPagination<EnvironmentCondition>,
      RequestWithPagination
    >({
      query: (params) => ({
        url: '/environment-conditions',
        method: 'GET',
        params,
      }),
      providesTags: ['Environment-condition'],
    }),
    createEnvironment: builder.mutation<DefaultResponse, CreateEvnCondition>({
      query: (body) => ({
        method: 'POST',
        url: '/environment-conditions',
        body
      }),
      invalidatesTags: ['Environment-condition'],
    }),
    updateEnvironment: builder.mutation<
      DefaultResponse,
      UpdateEnvConditionBody & { id: number }
    >({
      query: ({ id, ...body }) => ({
        method: 'PUT',
        url: `/environment-conditions/${id}`,
        body,
      }),
      invalidatesTags: ['Environment-condition'],
    }),
    deleteEnvironment: builder.mutation({
      query: ({id, params}) => ({
        method: 'DELETE',
        url: `/environment-conditions/${id}`,
        credentials: 'include',
        params
      }),
      invalidatesTags: ['Environment-condition'],
    }),
    getUnits: builder.query<ResponseWithPagination<Units>, RequestWithPagination>({
      query: () => ({
        url: '/units',
        method: 'GET',
        params: {
          'per-page': 0,
        },
      }),
    }),
    getStandartSamples: builder.query<ResponseWithPagination<StandartSample>, RequestWithPagination>({
      query: () => ({
        url: '/standart-samples',
        method: 'GET',
        params: {
          'per-page': 0,
        },
      }),
    }),
  }),
});

export const {
  useGetEnvironmentConditionsQuery,
  useLazyGetEnvironmentConditionsQuery,
  useGetUnitsQuery,
  useCreateEnvironmentMutation,
  useUpdateEnvironmentMutation,
  useDeleteEnvironmentMutation,
  useGetStandartSamplesQuery,
} = dictsApi;
