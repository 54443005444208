import React from 'react';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AssignmentIcon from '@mui/icons-material/Assignment';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { TFunction } from 'i18next';
import SpaIcon from '@mui/icons-material/Spa';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { NavItem } from '../types';
import { useAppSelector } from 'app/hooks/redux';

export const NavData = (t: TFunction): NavItem[] => {
  const { userInfo } = useAppSelector((state) => state.auth);
  if (userInfo && userInfo.tenant) {
    if (userInfo.tenant.type == 2) {
      return [
        {
          name: t('personal'),
          to: '/',
          icon: <PeopleAltIcon />,
        },
        {
          name: t('spaces'),
          to: '/rooms',
          icon: <MeetingRoomIcon />,
        },
        {
          name: t('environment'),
          to: '/',
          icon: <SpaIcon />,
        },
        {
          name: t('dicts'),
          icon: <LibraryBooksIcon />,
          items: [
            {
              name: t('environment_conditions'),
              to: '/dicts/environment-conditions',
            },
            {
              name: t('laboratories'),
              to: '/dicts/laboratories',
            },
          ],
        },
        {
          name: t('equipment'),
          to: '/equipment',
          icon: <PeopleAltIcon />,
        },
        {
          name: t('request.title'),
          to: '/request',
          icon: <PeopleAltIcon />,
        },
        {
          name: t('protocol.common.menuLabel'),
          to: '/protocol',
          icon: <AssignmentIcon />,
        },
        {
          name: t('accreditation'),
          to: '/accreditation',
          icon: <MenuBookIcon />,
        },
        // {
        //   name: t('goods_and_foreign_customers_services'),
        //   to: '/#',
        //   icon: <PeopleAltIcon />,
        // },
        // {
        //   name: t('requests'),
        //   to: '/#',
        //   icon: <PeopleAltIcon />,
        // },
        // {
        //   name: t('verification_and_validation'),
        //   to: '/#',
        //   icon: <PeopleAltIcon />,
        // },
        // {
        //   name: t('sampling'),
        //   to: '/#',
        //   icon: <PeopleAltIcon />,
        // },
        // {
        //   name: t('sample_handling'),
        //   to: '/#',
        //   icon: <PeopleAltIcon />,
        // },
        // {
        //   name: t('reliability_of_results'),
        //   to: '/#',
        //   icon: <PeopleAltIcon />,
        // },
        // {
        //   name: t('results_reports'),
        //   to: '/#',
        //   icon: <PeopleAltIcon />,
        // },
        // {
        //   name: t('complaints'),
        //   to: '/#',
        //   icon: <PeopleAltIcon />,
        // },
        // {
        //   name: t('inappropriate_work'),
        //   to: '/#',
        //   icon: <PeopleAltIcon />,
        // },
        // {
        //   name: t('SMK'),
        //   to: '/#',
        //   icon: <PeopleAltIcon />,
        // },
        // {
        //   name: t('contracts'),
        //   to: '/#',
        //   icon: <PeopleAltIcon />,
        // },
      ] as NavItem[];
    } else if (userInfo.tenant.type == 1) {
      return [
        {
          name: t('personal'),
          to: '/',
          icon: <PeopleAltIcon />,
        },
      ] as NavItem[];
    }
  }

  return [
    {
      name: t('personal'),
      to: '/',
      icon: <PeopleAltIcon />,
    },
  ] as NavItem[];
}


