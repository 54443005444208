import { configureStore } from '@reduxjs/toolkit';
import { appSlice } from 'entities/app/model/slice';
import { authApi } from 'entities/auth/api';
import { authSlice } from 'entities/auth/model/slice';
import { calibrationApi } from 'entities/calibration/api';
import { dictsApi } from 'entities/dicts/api';
import { equipmentApi } from 'entities/equipment/api';
import { laboratoriesApi } from 'entities/laboratories/api';
import { requestApi } from 'entities/request/api';
import { protocolApi } from 'entities/protocol/api';
import { staffApi } from 'entities/staff/api';
import { accreditationApi } from 'entities/accreditation/api';
import { utilsApi } from 'entities/utils/api';
import { materialApi } from 'entities/material/api';
import { request } from 'http';

export const store = configureStore({
  reducer: {
    [appSlice.name]: appSlice.reducer,
    [authSlice.name]: authSlice.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [utilsApi.reducerPath]: utilsApi.reducer,
    [laboratoriesApi.reducerPath]: laboratoriesApi.reducer,
    [dictsApi.reducerPath]: dictsApi.reducer,
    [staffApi.reducerPath]: staffApi.reducer,
    [equipmentApi.reducerPath]: equipmentApi.reducer,
    [calibrationApi.reducerPath]: calibrationApi.reducer,
    [requestApi.reducerPath]: requestApi.reducer,
    [protocolApi.reducerPath]: protocolApi.reducer,
    [accreditationApi.reducerPath]: accreditationApi.reducer,
    [materialApi.reducerPath]: materialApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      authApi.middleware,
      utilsApi.middleware,
      laboratoriesApi.middleware,
      dictsApi.middleware,
      staffApi.middleware,
      equipmentApi.middleware,
      calibrationApi.middleware,
      requestApi.middleware,
      protocolApi.middleware,
      accreditationApi.middleware,
      materialApi.middleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;
