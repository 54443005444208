import { CssBaseline } from '@mui/material';
import React from 'react';
import { BrowserRouter, Route, RouteProps, Routes } from 'react-router-dom';
import ScrollToTop from 'shared/scroll-to-top';
import LoginPage from './login-page';
import { PrivateRoute } from 'widgets/private-route';
import RoomsPage from './rooms-page';
import HomePage from './home-page';
import { Auth0Page } from './auth0';
import { MuiThemeProvider } from 'app/providers/mui-theme-provider';
import { DictsEnvironmentConditionsPage } from './dicts-environment-conditions-page';
import { DictsLaboratoriesPage } from './dicts-laboratories-page';
import EquipmentPage from './equipment';
//import EquipmentCreate from './equipment/create';
import EquipmentViewPage from './equipment/view';
import RequestViewPage from './request/view';
import RequestList from './request/list';
import Protocol from './protocol';
import ProtocolViewPage from './protocol/view';
import ProtocolObjectViewPage from './protocol/object';
import Accreditation from './accreditation';
import AccredStandartView from './accreditation/standart-list/view';

/*import CreateCalibration from './equipment/create/calibration';
import CalibrationProgam from './equipment/calibration-program';
import TechServiceGraph from './equipment/techservice-graph';
import TechServiceReg from './equipment/registration/tech-service';
import TechServiceReturn from './equipment/registration/techservice-return';
import InfoTechService from './equipment/registration/info-techservice';*/

const ROUTES: RouteProps[] = [
  { element: <LoginPage />, path: '/auth' },
  {
    path: '/',
    element: <HomePage />,
  },
  {
    path: '/rooms',
    element: (
      <PrivateRoute>
        <RoomsPage />
      </PrivateRoute>
    ),
  },
  {
    path: '/auth0',
    element: (
      <Auth0Page />
    ),
  },
  {
    path: '/dicts/environment-conditions',
    element: (
      <PrivateRoute>
        <DictsEnvironmentConditionsPage />
      </PrivateRoute>
    ),
  },
  {
    path: '/dicts/laboratories',
    element: (
      <PrivateRoute>
        <DictsLaboratoriesPage />
      </PrivateRoute>
    ),
  },
  {
    path: '/equipment',
    element: (
      <PrivateRoute>
        <EquipmentPage />
      </PrivateRoute>
    ),
  },
  {
    path: '/equipment/view/:viewId',
    element: (
      <PrivateRoute>
        <EquipmentViewPage />
      </PrivateRoute>
    ),
  },
  {
    path: '/request',
    element: (
      <PrivateRoute>
        <RequestList />
      </PrivateRoute>
    ),
  },
  {
    path: '/request/view/:viewId',
    element: (
      <PrivateRoute>
        <RequestViewPage />
      </PrivateRoute>
    ),
  },
  {
    path: '/protocol',
    element: (
      <PrivateRoute>
        <Protocol />
      </PrivateRoute>
    ),
  },
  {
    path: '/protocol/view/:viewId',
    element: (
      <PrivateRoute>
        <ProtocolViewPage />
      </PrivateRoute>
    ),
  },
  {
    path: '/protocol-object/view/:viewId',
    element: (
      <PrivateRoute>
        <ProtocolObjectViewPage />
      </PrivateRoute>
    ),
  },
  {
    path: '/accreditation',
    element: (
      <PrivateRoute>
        <Accreditation />
      </PrivateRoute>
    ),
  },
  {
    path: '/accreditation/standart/view/:viewId',
    element: (
      <PrivateRoute>
        <AccredStandartView />
      </PrivateRoute>
    ),
  },
  /*{
    path: '/equipment/create',
    element: (
      <PrivateRoute>
        <EquipmentCreate />
      </PrivateRoute>
    ),
  },
  {
    path: '/equipment/edit/:idEquip',
    element: (
      <PrivateRoute>
        <EquipmentCreate />
      </PrivateRoute>
    ),
  },
  {
    path: '/equipment/calibration/create/:idEquipVer',
    element: (
      <PrivateRoute>
        <CreateCalibration />
      </PrivateRoute>
    ),
  },
  {
    path: '/equipment/calibration/program',
    element: (
      <PrivateRoute>
        <CalibrationProgam />
      </PrivateRoute>
    ),
  },
  {
    path: '/equipment/tech-service',
    element: (
      <PrivateRoute>
        <TechServiceGraph />
      </PrivateRoute>
    ),
  },
  {
    path: '/equipment/tech-service/create/:idTechServ',
    element: (
      <PrivateRoute>
        <TechServiceReg />
      </PrivateRoute>
    ),
  },
  {
    path: '/equipment/tech-service-return/create',
    element: (
      <PrivateRoute>
        <TechServiceReturn />
      </PrivateRoute>
    ),
  },
  {
    path: '/equipment/info-tech-service/create/:idEquipShip',
    element: (
      <PrivateRoute>
        <InfoTechService />
      </PrivateRoute>
    ),
  },*/
];

export const Routing = () => {
  return (
    <BrowserRouter>
      <MuiThemeProvider>
        <CssBaseline />
        <ScrollToTop />
        <Routes>
          {ROUTES.map((route, idx) => (
            <Route {...route} key={idx} />
          ))}
        </Routes>
      </MuiThemeProvider>
    </BrowserRouter>
  );
};
