import React, { useEffect, useMemo, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
  Stack,
  StackProps,
  Typography,
  styled,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useAppSelector } from 'app/hooks/redux';
import { usePopper } from 'react-popper';
import { SidebarNavPopper } from './sidebar-nav-popper';
import { NavItem } from '../types';
import { Colors } from 'app/constants';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

interface Props {
  item: NavItem;
}

const AccordionContent = styled((props: StackProps) => (
  <Stack direction='row' spacing={2.5} alignItems='center' {...props} />
))(() => ({
  textDecoration: 'none',
})) as typeof Stack;

export const SidebarNavItem: React.FC<Props> = ({ item }) => {
  const { pathname } = useLocation();
  const { isSidebarOpen } = useAppSelector((state) => state.app);
  const { theme } = useAppSelector((state) => state.app);
  const [referenceEl, setReferenceEl] = useState<null | HTMLElement>(null);
  const [popperEl, setPopperEl] = useState<null | HTMLElement>(null);
  const [expanded, setExpanded] = useState(false);

  const [popperOpen, setPopperOpen] = useState(false);

  const { styles, attributes } = usePopper(referenceEl, popperEl, {
    placement: 'right-start',
  });

  const isActive =
    'items' in item && item.items
      ? item.items.some((el) => el.to === pathname)
      : pathname === item.to;

  const Icon = item.icon;

  const AccordionCustom = useMemo(
    () =>
      styled((props: AccordionProps) => (
        <Accordion disableGutters elevation={0} square {...props} />
      ))(() => ({
        '&:before': {
          display: 'none',
        },
        backgroundColor: isActive
          ? theme === 'light'
            ? Colors.WHITE
            : Colors.TEXT_DARK
          : 'inherit',
        borderRadius: '8px',
      })),
    [isActive, theme]
  );

  useEffect(() => {
    if (!isSidebarOpen) return setExpanded(false);

    if (isActive && !expanded) {
      setExpanded(true);
    }
  }, [isActive, isSidebarOpen]);

  return (
    <>
      <AccordionCustom
        elevation={0}
        expanded={expanded}
        onChange={(_event, exp) => setExpanded(exp)}
      >
        <AccordionSummary
          expandIcon={
            'items' in item && isSidebarOpen ? <KeyboardArrowDownIcon /> : <></>
          }
          sx={{
            mt: 0,
            padding: '12px 16px',
            '& .MuiAccordionSummary-content': {
              margin: 0,
            },
          }}
          ref={setReferenceEl}
          {...('items' in item
            ? {
                component: 'div',
              }
            : {
                component: Link,
                to: item.to,
              })}
          onMouseEnter={() => setPopperOpen(true)}
          onMouseLeave={() => setPopperOpen(false)}
        >
          <AccordionContent>
            {Icon}
            {isSidebarOpen && (
              <Typography
                sx={{
                  fontWeight: 600,
                  // color: Colors.TEXT_WHITE,
                  lineHeight: 1,
                }}
              >
                {item.name}
              </Typography>
            )}
          </AccordionContent>

          {!isSidebarOpen && item?.items && popperOpen && (
            <Box
              component='div'
              ref={setPopperEl}
              style={styles.popper}
              {...attributes.popper}
            >
              <SidebarNavPopper data={item.items} />
            </Box>
          )}
        </AccordionSummary>
        {item?.items && isSidebarOpen && (
          <AccordionDetails
            sx={{
              padding: '8px 16px',
            }}
          >
            <Stack spacing={1.5}>
              {item.items.map((el, idx) => {
                const isSubItemActive = pathname === el.to;

                return (
                  <Stack
                    key={idx}
                    spacing={1.25}
                    direction='row'
                    alignItems='center'
                    component={Link}
                    to={el.to}
                    sx={{
                      textDecoration: 'none',
                      backgroundColor: isSubItemActive
                        ? theme === 'light'
                          ? Colors.WHITE
                          : Colors.TEXT_DARK
                        : 'inherit',
                    }}
                  >
                    <Box component={KeyboardArrowRightIcon} />
                    <Typography
                      sx={{
                        // color: isSubItemActive ? Colors.TEXT_WHITE : '#8E6EAC',
                        fontWeight: 500,
                      }}
                    >
                      {el.name}
                    </Typography>
                  </Stack>
                );
              })}
            </Stack>
          </AccordionDetails>
        )}
      </AccordionCustom>
    </>
  );
};
