import React from "react";
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { styled, useTheme } from '@mui/material/styles';

import { DetailViewProps } from './types';

/*const TableRowStyled = styled(TableRow)`
  &:nth-of-type(even) {
    background-color: ${({ theme }) => theme.palette.background.default};
  }
  &:nth-of-type(odd) {
    background-color: ${({ theme }) => theme.palette.grey.A100};
  }
  & > td {
    color: ${({ theme }) => theme.palette.text.primary};
  }
  &:last-child td, &:last-child th: { border: 0 }
`;*/



const TableRowStyled = styled(TableRow)(({ theme }) => ({
  /*"&:nth-of-type(even)": {
    backgroundColor: "#e3e2e2"
  },
  "&:nth-of-type(odd)": {
    backgroundColor: "lightgrey"
  },*/
  // hide last border
  "&:last-of-type": {
    borderBottom: "0px solid black"
  },
  "&:first-of-type": {
    borderTop: "0px solid black"
  },
  "&:last-of-type td, &:last-child th": {
    borderBottom: "0px solid black"
  },
  "&:first-of-type td, &:first-child th": {
    borderTop: "0px solid black"
  },
  "th, td": {
    borderRight: "0",
    borderLeft: "0"    
  },
}));

const DetailView: React.FC<DetailViewProps> = ({
    data
  }) => {
  const theme = useTheme();
  return (
    <TableContainer>
      <Table sx={{ minWidth: 200 }} size="small" aria-label="simple table">
          <TableBody>
            {data.map((row, idx) => (
              <TableRowStyled
                key={idx}
              >
                <TableCell component="th" scope="row" sx={{ paddingTop:1, paddingBottom:1 }}>
                  <Typography variant="body1">{row.label}</Typography>
                </TableCell>
                <TableCell align={row.align ? row.align : "left"} >
                  <Typography variant="body1" sx={{ color: theme.palette.grey[600] }}>{row.value}</Typography>
                </TableCell>
              </TableRowStyled>
            ))}
              
          </TableBody>
      </Table>
    </TableContainer>
  );
}

export default DetailView;